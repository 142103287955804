import React from "react";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";

import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import LandingPage from "../components/landingpage";

import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
    post: sanityLandingPage(slug: { current: { eq: "home" } }) {
      id
      _updatedAt
      title
      subtitle
      slug {
        current
      }
      subfolder {
        folder
      }
      showConfetti
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      content {
        ... on SanityEmailCaptureForm {
          _key
          _type
          alignment
          brazeCustomAttributes {
            _key
            _type
            customAttributeKey
            customAttributeValue
          }
          _rawIntroPara(resolveReferences: { maxDepth: 10 })
          _rawThankYouText(resolveReferences: { maxDepth: 10 })
          brazeSubscriptionRef {
            groupName
            id
          }
          inputFieldPlaceholderText
          downloadUrl
          introPara {
            children {
              marks
              text
              _type
            }
            list
            style
          }
          linkText
          signupRedirectLink
          thankYouText {
            children {
              _type
              marks
              text
            }
            style
            list
          }
          title
        }
        ... on SanityWidgetSelection {
          _key
          _type
          widgets {
            title
            adZone
            mobileAdZone
            _type
            _rawBody
            _rawMainImage
            linkReference {
              ... on SanityInformation {
                id
                title
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
              ... on SanityLandingPage {
                id
                slug {
                  current
                }
                subfolder {
                  folder
                }
                _type
                title
              }
              ... on SanityLinkCategory {
                id
                url
                _type
              }
              ... on SanityPost {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanityQuestionPost {
                id
                _type
                slug {
                  current
                }
                title
              }
            }
            codeSnippet {
              children {
                text
              }
            }
            widgetType {
              title
            }
          }
        }
  ... on SanitySmallSpacerBlock {
          _key
          _type
          title
        }
        ... on SanityBreadcrumb {
          _key
          _type
          breadcrumbs {
            title
            linkReference {
              ... on SanityInformation {
                _type
                slug {
                  current
                }
                subfolder {
                  folder
                }
              }
              ... on SanityLinkCategory {
                _type
                url
              }
              ... on SanityPost {
                slug {
                  current
                }
                _type
              }
              ... on SanityQuestionPost {
                _type
                slug {
                  current
                }
              }
              ... on SanityLandingPage {
                id
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
            }
          }
        }

        ... on SanityBigBlockGroup {
          _key
          _type
          title
          styles
          layout
          arrows
          autoscroll
          smoothAutoscroll
          smoothAutoscrollSpeed
          bigBlockItems {
            linkText
            _rawIntroPara(resolveReferences: { maxDepth: 5 })
            title
            mainImage {
              ...SanityImage
              alt
            }
            reference {
              ... on SanityInformation {
                title
                _type
                slug {
                  current
                }
                subfolder {
                  folder
                }
              }
              ... on SanityLinkCategory {
                id
                url
                _type
              }
              ... on SanityPost {
                title
                subtitle
                _type
                slug {
                  current
                }
              }
              ... on SanityQuestionPost {
                title
                _type
                slug {
                  current
                }
              }
              ... on SanityLandingPage {
                id
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
            }
          }
        }
        ... on SanityHeroSection {
          _key
          _type
          styles
          title
          linkText
          downloadUrl
          brazeSubscriptionRef {
            groupName
            groupID
          }
          brazeCustomAttributes {
            customAttributeKey
            customAttributeValue
          }
          _rawThankYouText(resolveReferences: { maxDepth: 5 })
          videoUrl
          _rawIntroPara(resolveReferences: { maxDepth: 5 })
          alignment
          mainImage {
            ...SanityImage
            alt
          }
          reference {
            ... on SanityInformation {
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityWidgetCategory {
              id
              title
              _type
            }
            ... on SanityQuestionPost {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityLinkCategory {
              id
              url
              _type
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
        }
        ... on SanityHeaderBlock {
        _key
        _type
        title
        linkText
        _rawIntroPara(resolveReferences: {maxDepth: 5})
        alignment
        desktopImage {
          ...SanityImage
          alt
        }
        tabletImage {
          ...SanityImage
          alt
        }
        mobileImage {
          ...SanityImage
          alt
        }
        reference {
          ... on SanityInformation {
            _type
            slug {
              current
            }
            subfolder {
                  folder
             }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            id
            _type
            slug {
              current
            }
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }
          ... on SanityWidgetCategory {
            id
            title
            _type
          }
          ... on SanityLandingPage {
                id
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
            }
        }
        ... on SanityTitleSection {
          _key
          _type
          title
          styles
          alignment
          linkText
          reference {
            ... on SanityInformation {
              _type
              id
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityLinkCategory {
              id
              url
              _type
            }
            ... on SanityWidgetCategory {
              id
              title
              _type
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
          _rawIntroPara(resolveReferences: { maxDepth: 5 })
        }
        ... on SanityLatestArticlesBlock {
          articleCount
          _type
        }
        ... on SanityCalcReference {
          _type
          href
        }
        ... on SanityFilteredQuestionsBlock {
          _type
          title
        }
        ... on SanityAdvisersList {
          title
          _type
        }
      }
    }
    dictionary: allSanityDictionary {
      nodes {
        key
        value
      }
    }
  }
`;

const IndexPage = (props) => {

  const { data, errors } = props;
  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)
  console.log('post', post)

  post.dictionary = data && data.dictionary;
  try {
    return (
      <Layout isCoursePage={post.theme == "Boring Money Campus theme" ? true : false}>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Boring Money"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={!post.canonicalTagOverride && getPageUrl}
            canonicalTagOverride={post.canonicalTagOverride}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <LandingPage {...post} post={post} />}
      </Layout>
    );
  } catch (e) {
    console.log("IndexPageTemplate", e);
    return (null);
  }
};

export default IndexPage;
